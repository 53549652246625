<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="
            w-100
            d-lg-flex
            align-items-center
            justify-content-center
            px-5
        "
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="ART of Mentoring"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div
            class="
                w-100
                d-lg-flex
                align-items-center
                justify-content-center
                px-5
                is-displayed
              "
          >
            <b-img
              fluid
              :src="imgUrl"
              alt="ART of Mentoring"
              class="mb-5"
              style="vertical-align: text-top;"
            />
          </div>

          <b-card-text
            v-if="!isDone"
            class="mb-2"
          >
            Enter your email and we'll send you instructions to
            reset your password.
          </b-card-text>
          <b-card-text
            v-else
            class="mb-2"
          >
            An email containing instructions on how to
            reset your password has been sent to the provided email address.
          </b-card-text>

          <!-- form -->
          <validation-observer
            v-show="!isDone"
            ref="validationObserver"
            v-slot="{ pristine, invalid, handleSubmit }"
          >
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <validation-provider
                v-slot="validationContext"
                ref="email"
                name="email"
                rules="required|email"
              >
                <b-form-group
                  label="Email"
                  label-for="forgot-password-email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="email"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="pristine || invalid || isLoading"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                Send reset link
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="returnToLoginRoute">
              <feather-icon icon="ChevronLeftIcon" /> Back to
              login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
import {
  getValidationState,
} from "@/libs/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
  BSpinner,
} from "bootstrap-vue";
//eslint-disable-next-line
import { required, email } from "@validations";
import store from "@/store/index";
import authService from "@/services/authService";
import { mapGetters } from 'vuex';
import { makeErrorToast } from "@/libs/utils";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BSpinner,
  },
  props: {
    programId: {
      type: [Number, String],
      default: 0,
      required: false
    }
  },
  data() {
    return {
      email: "",
      sideImg: require("@/assets/images/logo/logo-h.png"),
      isDone: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("app", ["isParticipantPortal"]),
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/logo/logo-h.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
    returnToLoginRoute () {
      return !this.isParticipantPortal 
        ? { name: 'auth-login' } 
        : { name: 'participant-login', params: { programPath: this.$route.params.programPath }};
    }
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      try {
        const data = {
          email: this.email,
          ...(this.programId && {program_id: this.programId})
        };         
        await authService.forgotPassword(data);
        this.isDone = true;
        this.isLoading = false;
        return true;
      } catch (e) {
        this.isLoading = false;
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.validationObserver.setErrors(data.errors);
        } else if (status === 400) {
          this.$toast(makeErrorToast('Link has expired. Please request your program manager to resend access.'));
        } else {
          this.$toast(makeErrorToast(data.message));
          this.$log.error(e);
        }
        return false;
      }
    },
  },
  setup() {
    return {
      getValidationState
    };
  },
};
</script>

<style lang="scss">

@media screen and (min-width: 993px) {
  .is-displayed {
    display: none !important;
  }
}

@import "@/assets/scss/vue/pages/page-auth.scss";
</style>
